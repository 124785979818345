export const ChartType = {
  LINE: 'line',
  BAR: 'bar',
  HORIZONTAL_BAR: 'horizontalBar',
  PIE: 'pie',
  OUTLABELED: 'outlabeledPie',
  RADIAL_GAUGE: 'radialGauge',
};

const safely = (text: string): string => {
  return `##${text.replace(/\n/g, '').trim()}##`;
};

export const OPTIONS_OUTLABELEDPIE_CHART = {
  OUTLABELS_CHART: {
    id: 'OUTLABELS_CHART',
    callback: safely(`function(context) {
       const valuesBefore = context.dataset.data.reduce((a, b) => a + b, 0);
       const currentValue = context.dataset.data[context.dataIndex];

       if(currentValue/valuesBefore <= 0.1){
       return true;
        }
       return false;
       }`),
  },
  DATALABELS_CHART: {
    id: 'DATALABELS_CHART',
    callback: safely(`function(context) {
       const valuesBefore = context.dataset.data.reduce((a, b) => a + b, 0);
       const currentValue = context.dataset.data[context.dataIndex];

       if(currentValue/valuesBefore > 0.1){
       return true;
        }
       return false;
       }`),
  },
};

export const OPTIONS_NUMBER_FORMATS = {
  NONE: {
    id: 'NONE',
    callback: undefined,
  },

  THOUSAND_SEPARATOR: {
    id: 'THOUSAND_SEPARATOR',
    callback: safely(`function (value, context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = value;
      let decimals = null;

      if(typeof context === 'object') {
            if (context.chart) {
              decimals = context.chart.options.plugins.datalabels.decimals;
            }

            if(context.dataset) {
              val = context.dataset.data[context.dataIndex];
            }
          }

      return  !isNaN(val) ? (Number(val).toLocaleString('en', {minimumFractionDigits: decimals ?? 0, maximumFractionDigits: decimals ?? 0})) : val;
    }`),
  },

  CURRENCY: {
    id: 'CURRENCY',
    callback: safely(`function (value, context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = value;
      let decimals = null;

      if(typeof context === 'object') {
            if (context.chart) {
              decimals = context.chart.options.plugins.datalabels.decimals;
            }

            if(context.dataset) {
              val = context.dataset.data[context.dataIndex];
            }
      }

      const formattedValue = !isNaN(val) ? '$' + Number(val).toLocaleString('en', {minimumFractionDigits: decimals ?? 0, maximumFractionDigits: decimals ?? 0}) : val;

      return formattedValue.startsWith('$' + '-')
            ? formattedValue.replaceAll('$' + '-', '-' + '$')
            : formattedValue;
    }`),
  },

  CURRENCY_ACCOUNTING: {
    id: 'CURRENCY_ACCOUNTING',
    callback: safely(`function (value, context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = value;
      let decimals = null;

      if(typeof context === 'object') {
            if (context.chart) {
              decimals = context.chart.options.plugins.datalabels.decimals;
            }

            if(context.dataset) {
              val = context.dataset.data[context.dataIndex];
            }
       }

      const formattedValue = !isNaN(val) ? '$' + (Number(val).toLocaleString('en', {minimumFractionDigits: decimals ?? 0, maximumFractionDigits: decimals ?? 0})) : val;

      return formattedValue.startsWith('$' + '-')
                ? formattedValue.replaceAll('$' + '-', '(' + '$') + ')'
                : formattedValue;
    }`),
  },

  CURRENCY_THOUSAND_BASED: {
    id: 'CURRENCY_THOUSAND_BASED',
    callback: safely(`function (value, context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = value;
      let decimals = null;

      if(typeof context === 'object') {
            if (context.chart) {
              decimals = context.chart.options.plugins.datalabels.decimals;
            }

            if(context.dataset) {
              val = context.dataset.data[context.dataIndex];
            }
       }

      const formattedValue = !isNaN(val)
        ? Math.abs(Number(val)) >= 1000000 ? '$' + (Number(val) / 1000000).toFixed(decimals ?? 1) + 'M'
          : Math.abs(Number(val)) >= 1000 ? '$' + (Number(val) / 1000).toFixed(decimals ?? 1) + 'K'
          : '$' + Number(val).toFixed(decimals ?? 1)
        : val;

      return formattedValue.startsWith('$' + '-')
          ? formattedValue.replaceAll('$' + '-', '-' + '$')
          : formattedValue;
    }`),
  },

  CURRENCY_ACCOUNTING_THOUSAND_BASED: {
    id: 'CURRENCY_ACCOUNTING_THOUSAND_BASED',
    callback: safely(`function (value, context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = value;
      let decimals = null;

      if(typeof context === 'object') {
            if (context.chart) {
              decimals = context.chart.options.plugins.datalabels.decimals;
            }

            if(context.dataset) {
              val = context.dataset.data[context.dataIndex];
            }
       }

      const formattedValue = !isNaN(val)
        ? Math.abs(Number(val)) >= 1000000 ? '$' + (Number(val) / 1000000).toFixed(decimals ?? 1) + 'M'
          : Math.abs(Number(val)) >= 1000 ? '$' + (Number(val) / 1000).toFixed(decimals ?? 1) + 'K'
          : '$' + Number(val).toFixed(decimals ?? 1)
        : val;

      return formattedValue.startsWith('$' + '-')
          ? formattedValue.replaceAll('$' + '-', '(' + '$') + ')'
          : formattedValue;
    }`),
  },

  PERCENTAGE: {
    id: 'PERCENTAGE',
    callback: safely(`function (value, context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = value;
      let decimals = null;

      if(typeof context === 'object') {
            if (context.chart) {
              decimals = context.chart.options.plugins.datalabels.decimals;
            }

            if(context.dataset) {
              val = context.dataset.data[context.dataIndex];
            }
      }

      return !isNaN(val) ? (Number(val) / 100).toFixed(decimals ?? 0) + '%' : val;
    }`),
  },

  PERCENTAGE_NO_DIVIDED: {
    id: 'PERCENTAGE_NO_DIVIDED',
    callback: safely(`function (value, context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = value;
      let decimals = null;

      if(typeof context === 'object') {
            if (context.chart) {
              decimals = context.chart.options.plugins.datalabels.decimals;
            }

            if(context.dataset) {
              val = context.dataset.data[context.dataIndex];
            }
      }

      return !isNaN(val) ? Number(val).toFixed(decimals ?? 0) + '%' : val;
    }`),
  },

  PERCENTAGE_ACCOUNTING: {
    id: 'PERCENTAGE_ACCOUNTING',
    callback: safely(`function (value, context) {
      if(context === undefined || context === null ) {
        return;
      }

      let val = value;
      let decimals = null;

      if(typeof context === 'object') {
            if (context.chart) {
              decimals = context.chart.options.plugins.datalabels.decimals;
            }

            if(context.dataset) {
              val = context.dataset.data[context.dataIndex];
            }
      }

      const formattedValue = !isNaN(val) ? (Number(val) / 100).toFixed(decimals ?? 0) + '%' : val;

      if (formattedValue.startsWith('-')) {
        return '(' + formattedValue.replace('-', '') + ')';
      } else {
        return formattedValue;
      }
    }`),
  },
};

export const CURRENCY = {
  USD: { code: 'USD', symbol: '$' },
  AUD: { code: 'AUD', symbol: 'AU$' },
  NZD: { code: 'NZD', symbol: 'NZ$' },
  GBP: { code: 'GBP', symbol: '£' },
};

export const CURRENCY_CODES: string[] = [
  CURRENCY.USD.code,
  CURRENCY.AUD.code,
  CURRENCY.NZD.code,
  CURRENCY.GBP.code,
];
