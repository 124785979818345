import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EmailIcon from '@mui/icons-material/Email';
import {
  Badge,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  Typography,
} from '@mui/joy';
import { useCallback, useEffect, useRef, useState } from 'react';

import { convertTimeZone } from '../../helpers/date-time';
import { useGetActionExecutionInfo } from '../../services/lambda/action-execution-info/action-execution-info.api';
import logger from '../../services/logger';
import {
  useGetShowEmailStatusDialog,
  useMutateShowEmailStatusDialog,
} from '../../store/app-state/app-state.slice';
import safely from '../../utils/safely';
import {
  EmailProgress,
  LOCALSTORAGE_EMAIL_PROGRESS_KEY,
} from './email-status.interface';
import { saveEmailProgress } from './email-status-helper';

const EmailStatusDialog = () => {
  const EXECUTION_STATUS_INTERVAL = 5000;

  const localStorageEmailProgress = localStorage.getItem(
    LOCALSTORAGE_EMAIL_PROGRESS_KEY,
  );

  const showEmailStatus = useGetShowEmailStatusDialog();
  const setShowEmailStatus = useMutateShowEmailStatusDialog();

  const pollIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const [emailStatus, setEmailStatus] = useState<EmailProgress[] | undefined>();

  const [showDialog, setShowDialog] = useState(false);
  const [showBadge, setShowBadge] = useState(false);

  const [colorBadge, setColorBadge] = useState<
    'primary' | 'success' | 'danger'
  >('primary');

  const [getActionExecutionInfo, { isLoading: isActionExecutionInfoLoading }] =
    useGetActionExecutionInfo();

  const openEmailProgress = useCallback(async () => {
    const getJobExecutionStatus = async (executionId: string) => {
      logger.debug({ executionId }, 'EmailStatusDialog:getJobExecutionStatus');

      if (isActionExecutionInfoLoading) {
        return undefined;
      }

      const { data: executionInfo } = await getActionExecutionInfo({
        executionId,
      });

      return executionInfo;
    };

    // Get email progress data for each email job stored in localStorage
    const getEmailProgressData = async (dataFromLocalStorage: string) => {
      logger.debug(
        { dataFromLocalStorage },
        'EmailStatusDialog:getEmailProgressData',
      );

      // TODO: This needs to be validated
      const currentProgress = JSON.parse(
        dataFromLocalStorage,
      ) as unknown as EmailProgress[];

      const isAnyRunning = currentProgress.some(
        email => email.status === 'RUNNING',
      );

      const isAnyFailed = currentProgress.some(
        email => email.status === 'FAILED',
      );

      if (isAnyRunning) {
        setColorBadge('primary');
      } else if (isAnyFailed) {
        setColorBadge('danger');
      } else {
        setColorBadge('success');
      }

      for (const email of currentProgress.filter(
        email => email.status === 'RUNNING',
      )) {
        if (!email.id) {
          logger.error(
            { email },
            'EmailStatusDialog:getEmailProgressData:emailIdNotFound',
          );
          continue;
        }

        // Get job execution status
        const jobExecutionStatus = await getJobExecutionStatus(email.id);

        // Save email progress data to localStorage if jobExecutionStatus is available
        if (jobExecutionStatus) {
          saveEmailProgress(email.id, jobExecutionStatus, undefined);
        }
      }

      setEmailStatus(currentProgress);
    };

    const localStorageEmailProgress = localStorage.getItem(
      LOCALSTORAGE_EMAIL_PROGRESS_KEY,
    );

    // If there is no email progress data in localStorage, return
    if (!localStorageEmailProgress) {
      return;
    }

    // Get email progress data
    await getEmailProgressData(localStorageEmailProgress);

    // Clear interval if it exists
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
    }

    // Poll for email progress every EXECUTION_STATUS_INTERVAL ms
    pollIntervalRef.current = setInterval(() => {
      const currentEmailJobs = localStorage.getItem(
        LOCALSTORAGE_EMAIL_PROGRESS_KEY,
      );

      if (currentEmailJobs) {
        void getEmailProgressData(currentEmailJobs);
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const currentProgress: EmailProgress[] = currentEmailJobs
        ? JSON.parse(currentEmailJobs)
        : null;

      const isAnyRunning = currentProgress?.some(
        email => email.status === 'RUNNING',
      );

      if (!currentEmailJobs || currentEmailJobs === '[]' || !isAnyRunning) {
        if (pollIntervalRef.current) {
          clearInterval(pollIntervalRef.current);
        }
        return;
      }
    }, EXECUTION_STATUS_INTERVAL);
  }, [getActionExecutionInfo, isActionExecutionInfoLoading, pollIntervalRef]);

  // Delete email progress from localStorage
  const deleteEmailProgress = (id: string) => {
    const localStorageEmailProgress = localStorage.getItem(
      LOCALSTORAGE_EMAIL_PROGRESS_KEY,
    );

    if (localStorageEmailProgress) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const currentProgress: EmailProgress[] = JSON.parse(
        localStorageEmailProgress,
      );

      const dataFiltered = currentProgress.filter(email => email.id !== id);

      localStorage.setItem(
        LOCALSTORAGE_EMAIL_PROGRESS_KEY,
        JSON.stringify(dataFiltered),
      );

      if (!dataFiltered.length) {
        setShowBadge(false);
      }

      setEmailStatus(dataFiltered);
    }
  };

  // When localStorageEmailProgress is updated, check if there is any email in progress
  // If there is, show the badge
  useEffect(() => {
    if (localStorageEmailProgress) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const currentProgress: EmailProgress[] = JSON.parse(
        localStorageEmailProgress,
      );

      if (currentProgress.length) {
        setShowBadge(true);
        safely(openEmailProgress, 'EmailStatusDialog:openEmailProgress')();
      }

      const isAnyRunning = currentProgress.find(
        email => email.status === 'RUNNING',
      );

      if (isAnyRunning && !showEmailStatus) {
        setShowEmailStatus(true);
      }

      if (!isAnyRunning && showEmailStatus) {
        setShowEmailStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageEmailProgress]);

  // Open email progress dialog when showEmailStatus is true
  useEffect(() => {
    if (showEmailStatus) {
      setShowDialog(true);
    }
  }, [showEmailStatus]);

  // Handle mail icon click
  const handleOpenEmailProgress = () => {
    setShowDialog(true);
  };

  return (
    <>
      <IconButton
        className="w-full"
        size="sm"
        onClick={safely(
          handleOpenEmailProgress,
          'EmailStatusDialog:onShowDialog',
        )}
      >
        <Badge
          color={colorBadge}
          invisible={!showBadge}
          size="sm"
          badgeInset="10%"
        >
          <EmailIcon />
        </Badge>
      </IconButton>

      <Snackbar
        key="email-status"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        sx={{ pr: 0 }}
      >
        <Box
          sx={{
            maxHeight: '80vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            pr: 'var(--Snackbar-padding)',
          }}
        >
          <Typography mb={2} level="title-lg">
            Email Queue
          </Typography>

          {emailStatus && emailStatus.length > 0 ? (
            emailStatus
              .sort((a, b) => b.createdAt - a.createdAt)
              .map(email => (
                <Grid
                  key={email.id}
                  container
                  rowSpacing={0}
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ flexGrow: 1, marginBottom: 2 }}
                  width={500}
                >
                  <Grid xs={7}>
                    <Typography level="body-sm" noWrap>
                      {email.title}
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography
                      level="body-xs"
                      valign="bottom"
                      sx={{ textAlign: 'right' }}
                    >
                      {convertTimeZone(
                        new Date(email.createdAt),
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                      )}
                    </Typography>
                  </Grid>

                  <Grid xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress
                          variant="outlined"
                          determinate
                          value={email.progress}
                          color={
                            email.status === 'RUNNING'
                              ? 'primary'
                              : email.status === 'SUCCEEDED'
                                ? 'success'
                                : email.status === 'FAILED' ||
                                    email.status === 'TIMED_OUT'
                                  ? 'danger'
                                  : 'neutral'
                          }
                        ></LinearProgress>
                      </Box>
                      <Box sx={{ height: 20 }}>
                        <IconButton
                          size="sm"
                          sx={{ minWidth: '100%', minHeight: 20, p: 0 }}
                          onClick={() => deleteEmailProgress(email.id)}
                        >
                          <DeleteForeverIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid xs={7}>
                    {email.filters && (
                      <Typography level="body-xs" valign="bottom">
                        {Object.keys(email.filters).map(key => (
                          <div key={key}>
                            {email.filters
                              ? Array.isArray(email.filters[key])
                                ? JSON.stringify(email.filters[key]).length > 45
                                  ? JSON.stringify(
                                      email.filters[key],
                                    ).substring(0, 45) + '...'
                                  : JSON.stringify(email.filters[key])
                                : (email.filters[key] as string)
                              : ''}
                          </div>
                        ))}
                      </Typography>
                    )}
                  </Grid>

                  <Grid xs={5}>
                    <Typography
                      level="body-xs"
                      valign="bottom"
                      sx={{ textAlign: 'right' }}
                    >
                      {email.details} {email.progress}%
                    </Typography>
                  </Grid>
                </Grid>
              ))
          ) : (
            <span>No data found</span>
          )}
        </Box>
      </Snackbar>
    </>
  );
};

export default EmailStatusDialog;
