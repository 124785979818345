export interface AppConfig {
  readonly engageApiBaseUrl: string;
  readonly lambdaApiBaseUrl: string;
  readonly idleCountdownStart: number;
  readonly maxIdleTime: number;
  readonly imageAssetsUrl: string;
  readonly brandingAssetsBaseUrl: string;
  readonly templateAssetsBaseUrl: string;
  readonly templatePreviewAssetsBaseUrl: string;
  readonly maxApiRetryAttempts: number;
  readonly useCompanyLogo: boolean;
  readonly useCompanyColors: boolean;
  readonly cognitoUserPoolId: string;
  readonly cognitoClientId: string;
  readonly beforeTokenExpiration: number;
  readonly minPasswordLength: number;
  readonly appEnv: string;
}

const appConfig: AppConfig = {
  engageApiBaseUrl: process.env.NEXT_PUBLIC_ENGAGE_API_BASE_URL ?? '',
  lambdaApiBaseUrl: process.env.NEXT_PUBLIC_LAMBDA_API_BASE_URL ?? '',
  idleCountdownStart: 120, // 2 minutes
  maxIdleTime: 1000 * 60 * 30, // 30 minutes
  imageAssetsUrl: process.env.NEXT_PUBLIC_IMAGE_ASSETS_URL ?? '',
  brandingAssetsBaseUrl: process.env.NEXT_PUBLIC_BRANDING_ASSETS_BASE_URL ?? '',
  templateAssetsBaseUrl: process.env.NEXT_PUBLIC_TEMPLATE_ASSETS_BASE_URL ?? '',
  templatePreviewAssetsBaseUrl:
    process.env.NEXT_PUBLIC_TEMPLATE_PREVIEW_ASSETS_BASE_URL ?? '',
  maxApiRetryAttempts: 3,
  useCompanyLogo: false,
  useCompanyColors: false,
  cognitoUserPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  cognitoClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
  beforeTokenExpiration: 1000 * 60 * 1, // 1 minutes
  minPasswordLength: 12,
  appEnv:
    process.env.NEXT_PUBLIC_APP_ENV ?? process.env.NODE_ENV ?? 'production',
};

export default appConfig;
